import React, { useEffect, useState } from "react";
import scrollTo from 'gatsby-plugin-smoothscroll';

import {
  MenuWrapper,
  BtnContent,
  MenuName,
  SideMenu,
  ContainerMenu,
  NavWrapper,
  UnList,
  List,
  Link,
  BoxText,
  TextDescription,
  UlSocial,
  LiSocial,
  IconLink,
  TwitterWrapper,
  InstaWrapper,
} from "./styled"

const scrollToHeader = Header => () => scrollTo(Header)

const scrollToComandos = ComandosVoz => () => scrollTo(ComandosVoz)

const scrollToTrovo = Trovo => () => scrollTo(Trovo)

const scrollToLinks = Links => () => scrollTo(Links)

const scrollToYoutube = Youtube => () => scrollTo(Youtube)

const scrollToContato = Contato => () => scrollTo(Contato)

const Menu = () => {
  const [scroll, setScroll] = useState(0)

  useEffect(() => {
    document.addEventListener("scroll", () => {
      if (window.scrollY > 50) {
        setScroll(window.scrollY)
      } else {
        setScroll(0)
      }
    })
  }, [scroll])

  return (
    <MenuWrapper>
      <BtnContent htmlFor="bt_menu" scroll={scroll}>
        <MenuName>Menu</MenuName>
        &#9776;
      </BtnContent>

      <input type="checkbox" id="bt_menu" />

      <SideMenu>
        <ContainerMenu>
          <NavWrapper>
            <UnList>
              <List>
                <Link onClick={scrollToHeader("#Header")}>Bad quem?</Link>
              </List>
              <List>
                <Link onClick={scrollToTrovo("#Trovo")}>Live na Trovo!</Link>
              </List>
              <List>
                <Link onClick={scrollToLinks("#Links")}>Trabalhos com 3D</Link>
              </List>
              <List>
                <Link onClick={scrollToLinks("#Links")}>Design de jogos</Link>
              </List>
              <List>
                <Link onClick={scrollToLinks("#Comandos")}>Ajude o templo!</Link>
              </List>
              <List>
                <Link onClick={scrollToYoutube("#Youtube")}>Parceiros</Link>
              </List>
              <List>
                <Link onClick={scrollToContato("#Contato")}>Contato</Link>
              </List>
            </UnList>
          </NavWrapper>

          <BoxText>
            <TextDescription>
              Você também pode me encontrar em várias redes sociais. Me segue lá, é só clicar aí em baixo.
            </TextDescription>
          </BoxText>

          <NavWrapper>
            <UlSocial>
              <LiSocial>
                <IconLink href="https://twitter.com/BadNinjaDX" target="_blank" rel="noreferrer">
                  <TwitterWrapper />
                </IconLink>
              </LiSocial>

              <LiSocial>
                <IconLink href="https://www.instagram.com/badninjadx/" target="_blank" rel="noreferrer">
                  <InstaWrapper />
                </IconLink>
              </LiSocial>
            </UlSocial>
          </NavWrapper>
        </ContainerMenu>
      </SideMenu>
    </MenuWrapper>
  )
}

export default Menu;
