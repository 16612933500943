import styled from "styled-components";

export const TwitchWrapper = styled.section`
  width: 100%;
  background: ${({ theme }) => theme.colors.blackDefault};
  display: flex;
  padding-bottom: 30px;
  ${({ theme }) => theme.media.phone`
    max-width: 500px;
  `}
  ${({ theme }) => theme.media.smallPhone`
    width: 104%;
  `}
`

export const ContainerTwitch = styled.div`
  ${ ({ theme }) => theme.setContainer()};
  color: white;
`

export const ContentHeader = styled.header`
  text-align: center;
  text-align: -webkit-center;
  margin-bottom: 30px;
  margin-top: 30px;
  width: 100%;
  max-width: 1000px;
  ${({ theme }) => theme.media.phone`
    max-width: 500px;
  `}
`

export const ContentTwitch = styled.div`
  width: 100%;

  ${({ theme }) => theme.media.tablet`
    grid-template-columns: 1fr;
  `}

  ${({ theme }) => theme.media.phone`
    grid-template-columns: 1fr;
    justify-items: center;
  `}
`