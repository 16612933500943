import React, { useEffect } from "react";
import Aos from "aos";
import Title from "@components/common/Title";
import Description from "@components/common/Description";
import {
  TrovoWrapper,
  ContainerTrovo,
  ContentHeader,
  ContentTrovo,
  FrameTrovo,
  FrameChatTrovo,
} from "./styled"

const Trovo = () => {
  useEffect(() => {
    Aos.init({ duration: 2000, offset: 210, once: true })
  }, [])

  return (
    <TrovoWrapper id="Trovo">
      <ContainerTrovo>
        <ContentHeader>
          <Title variant="primary">Live na Trovo!</Title>
          <Description>Lives de segunda a sábado, a partir das 18h.</Description>
        </ContentHeader>
        <ContentTrovo>
          <FrameTrovo title="Trovo Live Stream" type='text/html' width='70%' src='https://player.trovo.live/embed/player?streamername=BadNinjaDX&autoplay=1&muted=1&fullscreen=0' frameborder='0'></FrameTrovo>
          <FrameChatTrovo title="Trovo Live Chat" width='30%' src='https://player.trovo.live/chat/BadNinjaDX' frameborder='0'></FrameChatTrovo>
        </ContentTrovo>
      </ContainerTrovo>
    </TrovoWrapper>
  )
}

export default Trovo;