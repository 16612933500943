import styled from "styled-components"
import Icons from "../Icons"
import BgVermelho from "@images/bg-vermelho.jpg"
import BgPix from "@images/pix.png"

export const LinksWrapper = styled.section`
  width: 100%;
  background-image: url(${BgVermelho});
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  background-size: cover;
  opacity: 0.9;
  display: flex;
  padding-bottom: 30px;

  ${({ theme }) => theme.media.smallPhone`
    width: 104%;
  `}
`

export const ContainerLinks = styled.div`
  ${({ theme }) => theme.setContainer()};
  width: 86%;
  ${({ theme }) => theme.media.tablet`
    width: 80%;
  `}
  ${({ theme }) => theme.media.smallPhone`
    padding-left: 10px;
    padding-right: 10px;
    width: 90%;
  `}
`

export const ContentHeader = styled.header`
  text-align: center;
  text-align: -webkit-center;
  width: 100%;
  max-width: 1000px;
  ${({ theme }) => theme.media.phone`
    max-width: 500px;
  `}

  background-color: rgba(0,0,0,.8);
  border-radius: 20px;
  margin-top: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-family: ${({ theme }) => theme.fonts.MakanHati};
  color: white;
`

export const ContentLinks = styled.div`
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 20px;

  max-width: 1000px;
  ${({ theme }) => theme.media.phone`
    max-width: 500px;
  `}
  width: 100%;
  margin-top: 10px;
  text-align: center;
  text-align: -webkit-center;

  div.slick-slider {
    button.slick-arrow {
      display: none !important;
    }

    ul > li > button::before {
      font-size: 12px;
    }
  }
`

export const BorderLinks = styled.div`
  color: ${({ theme }) => theme.colors.grayTitle};
  border-style: none;
  &:focus {
    outline-style: none;
  }
`

const iconsLinks = `
  max-width: 130px;
  width: 100%;
  transition: all 0.6s;
`

export const AmazonWrapper = styled(Icons.Amazon)`
  ${iconsLinks}
  color: ${({ theme }) => theme.colors.grafit};

  &:hover {
    color: ${({ theme }) => theme.colors.whiteDefault};
  }
`

export const ReactWrapper = styled(Icons.ReactLogo)`
  ${iconsLinks}
  color: ${({ theme }) => theme.colors.grafit};

  &:hover {
    color: ${({ theme }) => theme.colors.bgMenu};
  }
`

export const DropboxWrapper = styled(Icons.Dropbox)`
  ${iconsLinks}
  color: ${({ theme }) => theme.colors.grafit};

  &:hover {
    color: ${({ theme }) => theme.colors.bgMenu};
  }
`

export const FirefoxWrapper = styled(Icons.Firefox)`
  ${iconsLinks}
  color: ${({ theme }) => theme.colors.grafit};

  &:hover {
    color: ${({ theme }) => theme.colors.bgMenu};
  }
`

export const Pix = styled.div`
  width: 330px;
  height: 385px;
  min-width: 330px;
  min-height: 385px;
  background-image: url(${BgPix});
  background-repeat: no-repeat;
  background-position: middle center;
  background-size: 330px 385px; 
`