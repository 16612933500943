import styled from "styled-components"
import BgAzul from "@images/bg-azul.jpg"
import Shuriken from "@images/bullet.png"

export const ContatoWrapper = styled.section`
  width: 100%;
  background-image: url(${BgAzul});
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  display: flex;
  padding-bottom: 30px;

  ${({ theme }) => theme.media.smallPhone`
    width: 104%;
  `}
`

export const ContainerContato = styled.div`
  ${({ theme }) => theme.setContainer()};
  ${({ theme }) => theme.media.tablet`
    width: 70%;
  `}

  ${({ theme }) => theme.media.smallPhone`
    padding-left: 10px;
    padding-right: 10px;
    width: 90%;
  `}
`

export const ContentHeader = styled.header`
  max-width: 1000px;
  max-height: 800px;
  width: 100%;
  text-align: center;
  text-align: -webkit-center;
  padding-top: 30px;
`

export const ContentContato = styled.div`
  width: 100%;
  max-width: 1000px;
  max-height: 800px;
  margin: 0 auto;

  font-family: ${({ theme }) => theme.fonts.MakanHati};
  font-weight: normal;
  font-size: 32px;
  margin: 0;
  line-height: 32px;
  letter-spacing: 0px;
  
  ${({ theme }) => theme.media.tablet`
    font-weight: normal;
    font-size: 28px;
    line-height: 28px;
  `}

  ${({ theme }) => theme.media.phone`
    font-weight: normal;
    width: initial;
    font-size: 30px;
    line-height: 30px;
    padding: 10px;
  `}    
`

export const ElementosContato = styled.div`
  width: 100%;
  max-height: 800px;
  display: inline-flex;

  & > * {
    width: 50%;
    color: white;
    padding-left: 15px;
    padding-right: 15px;
  }

  & > span ul li {
    list-style-image: url(${Shuriken});
    margin-bottom: 20px;
  }

  & > span ul li span,
  & > span ul li a {
    cursor: pointer;
  }

  & > span ul li span:hover,
  & > span ul li a:hover {
      transition: all 0.6s;
      color: ${({ theme }) => theme.colors.orange};
  }

`