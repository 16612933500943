import styled from "styled-components";

import Icons from "@components/Icons";

export const YoutubeWrapper = styled.section`
  display: flex;
  flex-direction: column;
  padding-bottom: 138px;
`

export const Title = styled.div`
  color: red;
`

export const BgYoutube = styled.div`
  width: 100%;
  display: flex;
`

export const ContainerYoutube = styled.div`
  ${({ theme }) => theme.setContainer()};
`

export const IntroWrapper = styled.header`
  text-align: center;
  text-align: -webkit-center;
  padding-top: 146px;
  width: 100%;

  &::after {
    content: "";
    display: block;
    width: 100%;
    max-width: 540px;
    border-bottom: 1px solid;
    border-color: ${({ theme }) => theme.colors.linelight};
    margin-bottom: 16px;
  }
`

export const YoutubeContent = styled.div`
  text-align: center;
  text-align: -webkit-center;
  width: 100%;
  max-width: 1130px;
  margin-top: -170px;
  display: grid;
  grid-template-columns: auto auto;

  ${({ theme }) => theme.media.tablet`
    grid-template-columns: auto;
  `}
`