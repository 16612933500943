import React from "react"

import { ThemeProvider } from "styled-components"
import Helmet from "react-helmet"

import theme from "@utils/theme/theme"

import Layout from "@components/Layout"
import Menu from "@components/Menu/"
import Header from "@components/Header"
import ComandosVoz from "@components/ComandosVoz"
import Twitch from "@components/Twitch"
import Trovo from "@components/Trovo"
import Youtube from "@components/Youtube"
import Links from "@components/Links"
import Contato from "@components/Contato"
import Footer from "@components/Footer"

import "aos/dist/aos.css"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

const IndexPage = () => (
  <ThemeProvider theme={theme}>
    <Helmet>
      <title>[ BadNinjaDX ]</title>
    </Helmet>
    <Layout>
      <Menu />
      <Header />
      {/*<ComandosVoz />*/}
      <Trovo />
      {/*<Youtube />*/}
      <Links />
      <Contato />
      <Footer />
    </Layout>
  </ThemeProvider>
)

export default IndexPage