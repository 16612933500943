import React, { useEffect } from "react"
import Aos from "aos"
import Title from "@components/common/Title"
import Description from "@components/common/Description"
import scrollTo from 'gatsby-plugin-smoothscroll';
import {
  ContatoWrapper,
  ContainerContato,
  ContentHeader,
  ContentContato,
  ElementosContato,
} from "./styled"
import {
  IconLink,
  Link,
  TwitterWrapper,
  InstaWrapper,
} from "../Menu/styled"

const scrollToTrovo = Trovo => () => scrollTo(Trovo)

const Contato = () => {
  useEffect(() => {
    Aos.init({ duration: 2000, offset: 210, once: true })
  }, [])

  return (
    <ContatoWrapper id="Contato">
      <ContainerContato>
        <ContentHeader>
          <Title variant="black">
            Contato
          </Title>
          <Description variant="white">Quer trocar uma idéia?<br /><br /></Description>
        </ContentHeader>
        
        <ContentContato>
          <ElementosContato>
            <iframe 
              src="https://discord.com/widget?id=866311335535706132&theme=dark" 
              width="300" 
              height="600" 
              allowtransparency="true" 
              frameBorder="0" 
              sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"
              title="Discord"
              >
            </iframe>
            <span>
              Você pode:
              <ul>
                <li><a href="https://discord.gg/Nzxv9ErA" target="_blank" rel="noreferrer">Colar no servidor do Discord.</a></li>
                <li><span onClick={scrollToTrovo("#Trovo")}>Me encontrar na live da Trovo.</span></li>
                <li><a href="mailto:contato@badninjadx.com?subject=Contato%20via%20badninjadx.com" target="_blank" rel="noreferrer">Enviar um e-mail.</a></li>
                <li>Chamar nas redes sociais &rarr;&nbsp;
                  <IconLink href="https://twitter.com/BadNinjaDX" target="_blank" rel="noreferrer">
                    <TwitterWrapper />
                  </IconLink>&nbsp;
                  <IconLink href="https://www.instagram.com/badninjadx/" target="_blank" rel="noreferrer">
                    <InstaWrapper />
                  </IconLink>
                </li>
              </ul>
            </span>
          </ElementosContato>
        </ContentContato>
        
      </ContainerContato>
    </ContatoWrapper>
  )
}

export default Contato