import React, { useEffect } from "react"
import Logo from "@components/Logo/"
import {
  FooterWrapper,
  ContainerFooter,
  ContentFooterLogo,
} from "./styled"

const Footer = () => {
  return (
    <FooterWrapper>
      <ContainerFooter>
        <ContentFooterLogo>
          <a href="https://mages.dev" target="_blank"><Logo footer /></a>
        </ContentFooterLogo>
      </ContainerFooter>
    </FooterWrapper>
  )
}

export default Footer