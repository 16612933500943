import { createGlobalStyle } from 'styled-components';

// font Un Americano a Parigi
import UnAmericanoAParigi from "@fonts/un_americano_a_parigi/UnAmericanoaParigi.otf"

// font Atomico
import Atomico from "@fonts/atomico/atomico.ttf"

// font Makan Hati
import MakanHati from "@fonts/makan_hati/MakanHati.ttf"


const GlobalStyle = createGlobalStyle`
  html, body, div, head {
    margin: 0;
    padding: 0;
    width: 100%;
  }

  a, a:hover, a:visited, a:link, a:active {
    color: ${({ theme }) => theme.colors.whiteDefault};
    text-decoration: none;
  }

  a:hover {
    color: ${({ theme }) => theme.colors.yellow};
  }

  @font-face {
    font-family: "UnAmericanoAParigi";
    src: url(${UnAmericanoAParigi});
  }

  @font-face {
    font-family: "Atomico";
    src: url(${Atomico});
  }  

  @font-face {
    font-family: "MakanHati";
    src: url(${MakanHati});
  }  
`

export default GlobalStyle;