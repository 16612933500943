import React, { useEffect } from "react";
import Aos from "aos";
import Title from "@components/common/Title";
import Description from "@components/common/Description";

import {
  HeaderWrapper,
  ContainerHeader,
  FrameDesenhos,
  BadNinjaDX,
  BadKunoichiDX,
  BadNinjaLogoWrapper,
  BadNinjaLogo,
  ContentHeader,
} from "./styled"

const Header = () => {
  useEffect(() => {
    Aos.init({ duration: 2000, offset: 210 })
  }, [])

  return (
    <HeaderWrapper id="Header">
      <ContainerHeader>
        <FrameDesenhos>
          <BadNinjaDX>
            <BadKunoichiDX>
              <BadNinjaLogoWrapper>
                <BadNinjaLogo />
              </BadNinjaLogoWrapper>
              <ContentHeader data-aos="fade-up">
                <Title variant="primary">Sejam todos muito bem vindos<br />ao templo do v&eacute;io ninja.</Title>
                <Description variant="primary">
                  Olá. Meu nome é Vagner e adoro jogos imersivos e bem difíceis.<br />
                  Mas o problema dos jogos difíceis é que já sou bem tiozão<br />e meus reflexos já não me acompanham mais.<br />
                  Caso goste de um senhorzinho determinado que só morre<br />e fala groselhas, esse com certeza é o seu lugar.
                </Description>
              </ContentHeader>
            </BadKunoichiDX>
          </BadNinjaDX>
        </FrameDesenhos>
      </ContainerHeader>
    </HeaderWrapper>
  )
}

export default Header;