import styled from "styled-components";
import BackVerde from "@images/bg-verde.jpg"
export const TrovoWrapper = styled.section`
  width: 100%;
  background: ${({ theme }) => theme.colors.blackDefault};
  display: flex;
  padding-bottom: 30px;
  ${({ theme }) => theme.media.phone`
    max-width: 500px;
  `}
  ${({ theme }) => theme.media.smallPhone`
    width: 104%;
  `}
  min-height: 900px;
  background-image: url(${BackVerde});
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  background-size: cover;
  display: flex;
  overflow: hidden;
`

export const ContainerTrovo = styled.div`
  ${ ({ theme }) => theme.setContainer()};
  color: white;
`

export const ContentHeader = styled.header`
  text-align: center;
  text-align: -webkit-center;
  margin-bottom: 30px;
  margin-top: 30px;
  ${({ theme }) => theme.media.phone`
    max-width: 500px;
  `}
`

export const ContentTrovo = styled.div`
  width: 100%;
  ${({ theme }) => theme.media.tablet`
    grid-template-columns: 1fr;
  `}

  ${({ theme }) => theme.media.phone`
    grid-template-columns: 1fr;
    justify-items: center;
  `}
  display: inline-flex;
`

export const FrameTrovo = styled.iframe`
  min-height: 600px;
  ${({ theme }) => theme.media.tablet`
    grid-template-columns: 1fr;
  `}

  ${({ theme }) => theme.media.phone`
    grid-template-columns: 1fr;
    justify-items: center;
  `}
`

export const FrameChatTrovo = styled.iframe`
min-height: 600px;
${({ theme }) => theme.media.tablet`
  grid-template-columns: 1fr;
`}

${({ theme }) => theme.media.phone`
  grid-template-columns: 1fr;
  justify-items: center;
`}
`