import React, { useEffect } from "react";
import Aos from "aos";

import Title from "@components/common/Title";
import Description from "@components/common/Description";

import {
  LinksWrapper,
  ContainerLinks,
  ContentHeader,
  Pix,
} from "./styled"

const Links = () => {
  useEffect(() => {
    Aos.init({ duration: 2000, offset: 210, once: true, });
  }, []);

  return (
    <LinksWrapper id="Links">
      <ContainerLinks>
        <ContentHeader>
          <Title>
            Ajude o templo!
          </Title>
          <Description>
          Ajude o seu Império Ninja favorito conquistar seu espaço por direito.<br />
          Com a sua contribuição, teremos recursos para dominar o mundo<br />
          e fazer do mesmo um lugar muito melhor.<br /><br />
          A dominação é a chave para a paz total.<br /><br />
          Sua participação é muito importante para podermos investir em recursos<br />
          que são indispensáveis para tal conquista. Muito obrigado.
          </Description>
        </ContentHeader>

        <ContentHeader>
          <a href="https://livepix.gg/badninjadx2099" target="_blank" rel="noreferrer">
            <Pix /><br />
            <Description>Clique ou escaneie o QR Code</Description>
          </a>
        </ContentHeader>
      </ContainerLinks>
    </LinksWrapper>
  );
}

export default Links;
