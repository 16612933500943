import styled from "styled-components"
import BackMarrom from "@images/bg-marrom.jpg"
import NinjaDX from "@images/BadNinjaDX.png"
import KunoichiDX from "@images/BadKunoichiDX.png"
import BadLogo from "@images/logo.png"

export const HeaderWrapper = styled.header`
  width: 100%;

  ${({ theme }) => theme.media.smallPhone`
    width: 104%;
  `}  
  height: 900px;
  background-image: url(${BackMarrom});
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  background-size: cover;
  display: flex;
  overflow: hidden;
`

export const ContainerHeader = styled.div`
  ${({ theme }) => theme.setContainer()};
  width: 100%;
  min-width: 100%;
  min-height: 100%;
  padding: 0;
`

export const ContentHeader = styled.div`
  width: 70%;
  height: 100%;
  text-align: center;
  max-width: 70%;
  margin: 0 auto;
`

export const FrameDesenhos = styled.div`
  width: 100%;
  ${({ theme }) => theme.media.smallPhone`
    width: 104%;
  `}  
  height: 100%;
`

export const BadNinjaDX = styled.div`
  width: 100%;
  height: 100%;
  background-image: url(${NinjaDX});
  background-repeat: no-repeat;
  background-position: bottom left;
  background-attachment: fixed;
`

export const BadKunoichiDX = styled.div`
  width: 100%;
  ${({ theme }) => theme.media.smallPhone`
    width: 104%;
  `}  
  height: 100%;
  padding-top: 15%;
  padding-bottom: 5%;
  background-image: url(${KunoichiDX});
  background-repeat: no-repeat;
  background-position: bottom right;
  background-attachment: fixed;
`

export const BadNinjaLogoWrapper = styled.div`
  width: 100%;
  ${({ theme }) => theme.media.smallPhone`
    width: 104%;
  `}  
  text-align: center;
`
export const BadNinjaLogo = styled.img`
  width: 500px;
  height: 100px;
  content: url(${BadLogo});
`