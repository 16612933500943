import React from "react"

import { LogoImgWrapper, LogoImg } from "./styled"

import logoWhite from "@images/logo_white.png"
import logoBlack from "@images/logo_black.png"

const Logo = ({ footer }) => (
  // <LogoWrapper>
  //   Nev<Highlight>Tec</Highlight>.
  // </LogoWrapper>
  <LogoImgWrapper>
    <LogoImg
      src={footer ? logoBlack : logoWhite}
      title="Mages.DEV"
    ></LogoImg>
  </LogoImgWrapper>
)

export default Logo
